.rdp-cell {
}

.rdp-weeknumber,
.rdp-day {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #f1f3f6;
  color: #000;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: #dd0331;
  color: #fff;
  border-radius: 4px;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 4px;
}

.rdp-cell,
.rdp-head_cell {
  width: 36px;
  height: 36px;
}

.rdp-nav_button {
  width: 24px;
  height: 24px;
}

.rdp-caption_label {
  font-size: 18px;
}

.rdp-months {
  justify-content: center;
}

.cus-day-picker-today {
  border: 1px solid #dd0331;
  color: #dd0331;
  background-color: transparent;
}

.cus-day-picker-selected,
.cus-day-picker-selected:hover {
  background-color: #dd0331;
  color: #fff;
}

.cus-day-picker-range-start,
.cus-day-picker-range-end {
  background-color: #dd0331;
}

.cus-day-picker-range-start {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.cus-day-picker-range-end {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.cus-day-picker-range-middle {
  background-color: rgba(221, 3, 49, 0.2);
}
