@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-spacing: 0px;
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(97, 97, 97, 0.3);
  height: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(97, 97, 97, 0.5);
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

@layer components {
  .page-container {
    @apply mx-auto w-full max-w-[1440px] px-2 md:px-6 lg:px-8 xl:px-10;
  }

  .page-section-title {
    @apply text-20 font-[600] leading-[1] md:text-[26px];
  }
  .page-section-subtitle {
    @apply mt-3 mb-4 text-[14px] font-[400] text-text-body md:mb-9;
  }

  /* Custom cohort */
  .table-cohort-cell {
    @apply min-h-[40px] text-center text-16 font-medium;
  }
  .table-border {
    @apply h-full border-[1px] border-solid border-white;
  }
  .table-head {
    @apply h-[60px] min-h-[40px] border-[1px] border-solid border-white bg-[#EDEDED] px-3 py-1 text-center text-16 font-[600] lg:h-[76px] lg:px-4 lg:py-3 xl:px-6;
  }
  .table-row-total {
    @apply h-full min-h-[40px] border-[1px] border-solid border-white bg-[#EDEDED] px-6 py-5 text-center text-16 font-light;
  }
  .table-row-title {
    @apply h-full min-h-[40px] border-[1px] border-solid border-white bg-[#EDEDED] px-3 py-1 text-right text-16 font-light lg:px-6 lg:py-3;
  }

  /* Custom day picker */
  .cus-day-picker-selected {
    @apply bg-primary text-white hover:bg-primary;
  }
  .cus-day-picker-today {
    @apply border border-solid border-primary bg-transparent text-primary hover:bg-bg-red-light;
  }
  .cus-day-picker-range-start {
    @apply rounded-tr-none rounded-br-none bg-primary;
  }
  .cus-day-picker-range-end {
    @apply rounded-tl-none rounded-bl-none bg-primary;
  }
  .cus-day-picker-range-middle {
    @apply bg-bg-red-light;
  }

  /* Custom Monetization */
  .monetization-table-head {
    @apply border border-solid border-white py-1 px-3 text-14 font-medium text-white lg:px-6 lg:py-3 lg:text-16;
  }

  .monetization-table-row-head {
    @apply border border-solid border-white bg-secondary py-1 px-3 text-center text-14 font-medium text-white lg:px-6 lg:py-3 lg:text-16;
  }

  .monetization-table-cell {
    @apply border border-solid border-white px-2 py-1 text-center text-14 font-normal text-text-heading lg:px-4 lg:text-16;
  }
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}

@media (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}
